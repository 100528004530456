import React from 'react'
import styled from 'styled-components'
import { PageHead, PageHeadProps } from 'components/atoms'

export interface HeadSectionProps {
  readonly pageHeadProps: PageHeadProps
}

type HeadSectionPropsClassName = HeadSectionProps & {
  readonly className: string
}

function Component({ className, pageHeadProps }: HeadSectionPropsClassName) {
  return (
    <div className={className}>
      <PageHead
        headingText={pageHeadProps.headingText}
        isWide={pageHeadProps?.isWide}
        isAuto={pageHeadProps?.isAuto}
        headingLevel={pageHeadProps.headingLevel}
      />
    </div>
  )
}

const StyledComponent = styled(Component)<HeadSectionPropsClassName>``

export const HeadSection: (props: HeadSectionProps) => JSX.Element = StyledComponent

export default HeadSection
