import React from 'react'
import styled from 'styled-components'
import HeadSection from 'components/section/headSection'
import { INewsArchive } from 'components/group/index'
import ArchiveSection from 'components/section/archiveSection'
import {} from 'variables/_index'

export interface ArchiveTemplateProps {
  readonly headingText: string
  readonly entryNodes: Array<INewsArchive>
  readonly totalCount: number
}

type ArchiveTemplatePropsClassName = ArchiveTemplateProps & {
  readonly className: string
}

function Component({ className, headingText, entryNodes, totalCount }: ArchiveTemplatePropsClassName) {
  return (
    <div className={className}>
      <HeadSection
        pageHeadProps={{
          headingText: headingText,
          headingLevel: 'h1',
        }}
      />
      <ArchiveSection entryNodes={entryNodes} totalCount={totalCount} />
    </div>
  )
}

const StyledComponent = styled(Component)<ArchiveTemplatePropsClassName>``

export const ArchiveTemplate: (props: ArchiveTemplateProps) => JSX.Element = StyledComponent

export default ArchiveTemplate
