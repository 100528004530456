import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'components/seo'
import { INewsArchive } from 'components/group/index'
import Layout from 'layout/base'
import ArchiveTemplate from 'templates/archiveTemplate'
import { TypeBreadcrumb } from 'types/breadcrumb'
import { siteStructure } from 'utils/site'
import globalTitle from 'utils/globalTitle'

interface NewsArchivePageProps {
  readonly data: {
    allWpPost: {
      nodes: Array<INewsArchive>
      totalCount: number
    }
  }
  pageContext: TypeBreadcrumb
  location: Location
}

const NewsArchivePage = ({ data, pageContext, location }: NewsArchivePageProps): JSX.Element => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const breadcrumbItems = crumbs.map(crumb => {
    return crumb.crumbLabel in siteStructure
      ? {
          ...crumb,
          ...{ crumbLabel: siteStructure[crumb.crumbLabel]?.name },
        }
      : { ...crumb }
  })

  return (
    <Layout pageType="page" breadcrumbItems={breadcrumbItems} ctaType="type1">
      <Seo
        title={`お知らせ一覧${globalTitle(location.pathname)}`}
        description="成績アップを目指すためのオンライン個別指導塾、Fit NET STUDYのお知らせ一覧ページです。"
        path={location.pathname}
      />
      <ArchiveTemplate headingText="お知らせ" entryNodes={data.allWpPost.nodes} totalCount={data.allWpPost.totalCount} />
    </Layout>
  )
}

export default NewsArchivePage

export const query = graphql`
  query {
    allWpPost(limit: 10, skip: 0, sort: { fields: date, order: DESC }) {
      totalCount
      nodes {
        content
        slug
        title
        databaseId
        date(formatString: "YYYY/MM/DD")
      }
    }
  }
`
