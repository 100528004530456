import React from 'react'
import styled from 'styled-components'
import { NewsCards, INewsArchive, Pagination } from 'components/group/index'
import { rem, breakpoints, innerWidth, innerPadding, innerDummyPadding } from 'variables/_index'

export interface ArchiveSectionProps {
  readonly entryNodes: Array<INewsArchive>
  readonly totalCount: number
}

type ArchiveSectionPropsClassName = ArchiveSectionProps & {
  readonly className: string
}

function Component({ className, entryNodes, totalCount }: ArchiveSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-cards">
          <NewsCards newsNodes={entryNodes} />
        </div>
        <div className="e-pagination">
          <Pagination totalCount={totalCount} />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<ArchiveSectionPropsClassName>`
  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: ${rem(60)};
    padding-bottom: ${rem(60)};

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-top: ${rem(80)};
      padding-bottom: ${rem(80)};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-cards {
    }

    > .e-pagination {
      margin-top: ${rem(40)};
      text-align: center;
    }
  }
`

export const ArchiveSection: (props: ArchiveSectionProps) => JSX.Element = StyledComponent

export default ArchiveSection
